import Vue from 'vue'
import Vuex from 'vuex'
import {i18n} from '@/plugins/i18n'

import deposits from '@/store/deposits'
import projects from '@/store/projects'
import searchCode from '@/store/search-code'
import error from '@/store/error'
import notify from '@/store/notify'
import subscriptions from '@/store/subscriptions'

import depositsBusiness from '@/store/business/deposits'
import projectsBusiness from '@/store/business/projects'
import teammatesBusiness from '@/store/business/teammates'
import subscriptionsBusiness from '@/store/business/subscriptions'

import {RepositoryFactory} from '@/repositories/_repository-factory'
import {COUNTRIES} from '@/store/const-countries-code'

const authRepository = RepositoryFactory.get('auth')
const configRepository = RepositoryFactory.get('config')
// Can be changed in action loadProfile
let userRepository = RepositoryFactory.get('user')
let businessUserRepository = RepositoryFactory.get('businessUser')

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    deposits,
    projects,
    error,
    notify,
    subscriptions,
    depositsBusiness,
    projectsBusiness,
    teammatesBusiness,
    subscriptionsBusiness,
    searchCode,
  },
  state: {
    ready: false,
    config: {
      locale: 'en',
    },
    profile: {},
    loaders: {
      auth: {title: 'Auth', status: false},
      config: {title: 'Config', status: false},
      profile: {title: 'Profile', status: false},
      deposits: {title: 'Deposits', status: false}
    }
  },
  mutations: {
    setNotReady: state => {
      state.ready = false
    },
    setReady: state => {
      state.ready = true
    },
    setConfig: (state, config) => {
      state.config = {...config}
    },
    setProfile: (state, profile) => {
      state.profile = {...state.profile, ...profile}
      if (!state.profile.name) {
        state.profile.name =
          `${state.profile.first_name} ${state.profile.last_name}`
      }
      if (state.profile.company) {
        state.profile.company.address ??= {}
        state.profile.company.director ??= {}
        state.profile.company.director.nominative ??= {}
        state.profile.company.director.genitive ??= {}
      }
    },
    setLoaded: (state, loader) => {
      state.loaders[loader].status = true
    },
    setUnloaded: (state, loader) => {
      state.loaders[loader].status = false
    },

    // Teammates mutations
    addTeammate: (state, teammate) => {
      state.profile.company.teammates.push(teammate)
    },
    removeTeammate: (state, accountId) => {
      let i = state.profile.company.teammates
        .findIndex(tm => tm.account_id === accountId)
      if (i !== -1) {
        state.profile.company.teammates.splice(i, 1)
      }
    },

    setCompany: (state, company) => {
      state.profile.company = {...state.profile.company, ...company}
    },
  },
  getters: {
    getCurrencies: state => {
      if (!state.config.currencies) return []
      return Object.keys(state.config.currencies)
        .map(code => ({
          text: state.config.currencies[code].name,
          value: code
        }))
    },
    getCountries: () => {
      return COUNTRIES.map(({alpha2, country}) => ({
        text: country,
        value: alpha2
      }))
    },
    getLanguagesOptions: state => {
      let langs = state.config.deposit?.types?.implementation?.languages
      if (!langs) return []
      return Object.keys(langs).map(lng => ({text: langs[lng], value: lng}))
    },
    getTerritoriesOptions: state => {
      let territories = state.config?.deposit?.actors?.territories
      if (!territories) return []
      return Object.keys(territories)
        .map(t => ({text: territories[t], value: t}))
    },
    getDepositTypes: state => {
      if (!state.config.deposit.types) return []
      return Object.keys(state.config.deposit.types)
        .map(id => ({
          text: state.config.deposit.types[id].title,
          value: id
        }))
    },
    getBusinessTypes: state => {
      if (!state.config.deposit.actor.business_type) return []
      return Object.keys(state.config.deposit.actor.business_type)
        .map(id => ({
          text: state.config.deposit.actor.business_type[id].title,
          value: id
        }))
    },
    getAvatarStyle: state => {
      return state.profile.avatar
        ? {backgroundImage: `url("${state.profile.avatar}")`}
        : {}
    },
    getActivePlan: state => {
      return state.profile.subscribed?.plan || {}
    },
    getActivePlanBusiness: state => {
      return state.profile.subscriptions?.find(s => s.status === 'active') || {}
    },
  },
  actions: {
    async registerUser ({commit}, user) {
      return authRepository.register(user)
    },
    async resendVerificationLink () {
      return authRepository.resendVerificationLink()
    },
    async init ({commit, dispatch, state}) {
      if (Vue.$auth.check()) {
        let refreshTask = Vue.$auth.refresh()
        let configTask = dispatch('loadConfig')
        await refreshTask
        await configTask
      } else {
        await dispatch('loadConfig')
      }
      await i18n.i18next.changeLanguage(state.config.locale)

      if (!Vue.$auth.watch.authenticating) commit('setReady')
      Vue.$auth.watch.$emit('ready')
    },
    async loadConfig ({commit}) {
      let {data: {data, result}} = await configRepository.get()

      if (result) commit('setConfig', data)
      commit('setLoaded', 'config')
    },
    async loadProfile ({commit}) {
      let {data: {data, result}} = await userRepository.profile()
      if (result) commit('setProfile', data)
      commit('setLoaded', 'profile')

      if (data.accountType === 'business') {
        userRepository = RepositoryFactory.get('businessUser')
      }
    },
    async updateProfile ({commit}, data) {
      let response = await userRepository.update(data)
      if (response.data.result) commit('setProfile', response.data.data)
    },
    clearProfile ({commit}) {
      commit('setProfile', {})
      commit('setUnloaded', 'profile')
    },

    async updateCompany ({commit}, data) {
      let response = await userRepository.updateCompany(data)
      if (response.data.result) commit('setCompany', response.data.data.details)
    },

    async switchToBusinessAccount ({commit}, data) {
      await businessUserRepository.switchToBusinessAccount(data)
    },
    async getEnquiryDetails ({commit}, id) {
      let enq = businessUserRepository.getEnquiryDetails(id)
      return enq
    },

    async sendVerificationCode ({state}, phone) {
      await authRepository.sendVerificationCode(phone)
    },
    async resendVerificationCode () {
      await authRepository.resendVerificationCode()
    },
    async verifyCode ({dispatch}, code) {
      let {data} = await authRepository.verifyCode(code)
      return data
    },
  }
})
