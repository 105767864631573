import auth from './v1/auth-repository'
import config from './v1/config-repository'
import deposits from './v1/deposits-repository'
import projects from './v1/projects-repository'
import subscription from './v1/subscription-repository'
import user from './v1/user-repository'
import searchCode from './v1/search-code-repository'

import businessDeposits from './v2/deposits-repository'
import businessProjects from './v2/projects-repository'
import businessTeammates from './v2/teammates-repository'
import businessSubscription from './v2/subscription-repository'
import businessUser from './v2/user-repository'

const repositories = {
  auth,
  config,
  deposits,
  projects,
  searchCode,
  subscription,
  user,
  businessDeposits,
  businessProjects,
  businessTeammates,
  businessSubscription,
  businessUser,
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
