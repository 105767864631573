import Vue from 'vue'

export default {
  plans () {
    return Vue.$http.get('v2/business/plans')
  },
  /*
  setupIntent () {
    return Vue.$http.get('v2/payments/stripe/setup-intent')
  },
  paymentMethods () {
    return Vue.$http.get('v2/payments/stripe/methods')
  },
  // eslint-disable-next-line camelcase
  removeMethod (payment_method_id) {
    return Vue.$http.post('v2/payments/stripe/method/remove', {payment_method_id})
  },
  // eslint-disable-next-line camelcase
  createSubscription (plan_id, payment_method_id) {
    return Vue.$http.post('v2/subscriptions/stripe', {plan_id, payment_method_id})
  },
  // eslint-disable-next-line camelcase
  cancelSubscription (subscription_id) {
    return Vue.$http.post('v2/subscriptions/stripe/cancel', {subscription_id})
  },
  // eslint-disable-next-line camelcase
  resumeSubscription (subscription_id) {
    return Vue.$http.post('v2/subscriptions/stripe/resume', {subscription_id})
  }
 */
}
