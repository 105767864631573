import Vue from 'vue'
import {makeFormData, omitEmpty} from '@/util/util'
import {RepositoryFactory} from '@/repositories/_repository-factory'
import {sections} from '@/store/const-deposit-statuses'

const depositsRepository = RepositoryFactory.get('businessDeposits')

export default {
  namespaced: true,
  state: {
    deposits: [],
    tags: [],
    tagsLoaded: false,
    pagination: {
      perPageVariants: [5, 10, 20],
      perPage: 5,
      currentPage: 1,
      total: 0,
      totalPages: 0
    },
    filters: {
      type: null
    },
    limits: {
      name: 191,
      version: 255,
      description: 191,
      contentText: 2048,
      os_name: 255,
      os_version: 255,
    }
  },
  getters: {
    getById: state => id => {
      return state.deposits.find(d => d.id === id * 1)
    },
    editable: (state, getters) => id => {
      return getters.getById(id).status === 'draft'
    },
  },
  mutations: {
    setDeposits: (state, deposits) => {
      state.deposits = deposits
    },
    setDeposit: (state, deposit) => {
      let i = state.deposits.findIndex(d => d.id === deposit.id)
      if (i === -1) state.deposits.push(deposit)
      else Vue.set(state.deposits, i, deposit)
    },
    updateDeposit: (state, deposit) => {
      let i = state.deposits.findIndex(d => d.id === deposit.id)
      if (i === -1) {
        state.deposits.push(deposit)
      } else {
        for (let [key, val] of Object.entries(deposit)) {
          Vue.set(state.deposits[i], key, val)
        }
      }
    },
    removeDeposit: (state, id) => {
      let i = state.deposits.findIndex(d => d.id === id)
      if (i !== -1) state.deposits.splice(i, 1)
    },
    setPagination: (state, pagination) => {
      state.pagination.perPage = pagination.per_page
      state.pagination.currentPage = pagination.current_page
      state.pagination.total = pagination.total
      state.pagination.totalPages = Math.ceil(pagination.total / pagination.per_page)
      state.pagination.depositsCount = pagination.depositsCount
      state.pagination.depositsFilteredCount = pagination.depositsFilteredCount
    },
    clearPagination: state => {
      state.pagination.currentPage = 1
      state.pagination.total = 0
      state.pagination.totalPages = 0
    },
    setPerPage: (state, perPage) => {
      perPage = perPage * 1
      if (state.pagination.perPageVariants.includes(perPage)) {
        state.pagination.perPage = perPage
        localStorage.setItem('perPage', perPage)
      }
    },
    // Tags mutations
    setTagsLoaded: (state, loaded) => {
      state.tagsLoaded = loaded
    },
    setTags: (state, tags) => {
      state.tags = tags
    },
    // Filters mutation
    setFilterType: (state, type) => {
      state.filters.type = type
    }
  },
  actions: {
    async dpLoadOne ({commit}, id) {
      let {data: {data}} = await depositsRepository.get(id)
      commit('setDeposits', [data])
    },
    async dpLoad ({commit, state}, {
      page = 1,
      projectId,
      search,
      sectionId,
      type,
    }) {
      let filter = {
        status: sections.find(s => s.id === sectionId).statuses.join(',')
      }
      if (projectId !== 'all') filter['project.id'] = projectId
      if (type) filter.type = type

      let perPage = Number(localStorage.getItem('perPage'))
      if (
        state.pagination.perPageVariants.includes(perPage) &&
        state.pagination.perPage !== perPage
      ) {
        commit('setPerPage', perPage)
      }

      let response = await depositsRepository.deposits({
        perPage: state.pagination.perPage,
        currentPage: page,
        filter,
        search,
      })
      let {data: {data: {data, pagination}}} = response

      commit('setPagination', pagination)
      commit('setDeposits', data)
      commit('setLoaded', 'deposits', {root: true})
    },
    dpClear ({commit}) {
      commit('setDeposits', [])
      commit('projectsBusiness/setProjects', [], {root: true})
      commit('projectsBusiness/setLoaded', false, {root: true})
      commit('clearPagination')
      commit('setUnloaded', 'deposits', {root: true})
    },
    async dpAdd ({commit}, deposit) {
      await depositsRepository.add(omitEmpty(deposit))
    },
    async dpAddWithFiles ({commit}, deposit) {
      await depositsRepository.add(makeFormData(deposit))
    },
    async dpUpdate ({commit}, deposit) {
      await depositsRepository.update(omitEmpty(deposit))
    },
    async dpUpdateWithFiles ({commit}, deposit) {
      await depositsRepository.updateWithFiles(
        deposit.id,
        makeFormData(deposit)
      )
    },
    async dpRemove ({commit}, id) {
      await depositsRepository.remove(id)
      commit('removeDeposit', id)
    },
    async dpRegister ({commit}, id) {
      await depositsRepository.arch(id)
      let {data: {data}} = await depositsRepository.register(id)
      commit('updateDeposit', data)
    },
    dpDownloadCert ({commit}, id) {
      return depositsRepository.certificate(id)
    },
    // Tags actions
    async dpLoadTags ({commit}) {
      let {data: {data}} = await depositsRepository.loadTags()
      commit('setTags', data)
      commit('setTagsLoaded', true)
    }
  }
}
