import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import {i18n} from '@/plugins/i18n'
import {AuthPlugin} from '@/plugins/auth'
import {AxiosPlugin} from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import '@/scss/main.scss'

Vue.config.productionTip = false

Vue.use(AxiosPlugin)
Vue.use(AuthPlugin, {
  skipUrls: ['auth'],
  refreshTokenInterval: window.env.REFRESH_TOKEN_INTERVAL * 1000,
  onLoginRedirect: {name: 'profile'},
  onLogoutRedirect: window.env.LOGOUT_URL,
  loginUrl: window.env.IPCHAIN_LOGIN_URL,
})
Vue.use(VueGtm, {
  id: 'GTM-NFR9R56',
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: false, // process.env.NODE_ENV === 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  // loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // If router, you can exclude some routes name (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

new Vue({
  router,
  store,
  i18n,

  created () {
    this.$auth.watch.$on('logged-in', async () => {
      this.$store.commit('setLoaded', 'auth')
      this.$store.commit('setNotReady')

      try {
        await this.$store.dispatch('loadProfile')
      } catch (e) {
        this.$store.commit('error/set', {mode: 'global', error: e})
      }

      this.$store.commit('setReady')
    })

    this.$auth.watch.$on('logged-out', () => {
      this.$store.commit('setUnloaded', 'auth')

      this.$store.dispatch('clearProfile')
      this.$store.dispatch('deposits/dpClear')
      this.$store.dispatch('depositsBusiness/dpClear')

      window.location.href = this.$auth.options.onLogoutRedirect
    })
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')
