<template>
  <div>
    <v-snackbar
      :timeout="timeout"
      :value="visible"
      :color="color"
      elevation="0"
      top
      @input="input"
    >
      {{ text }}
      <template v-slot:action>
        <v-btn text :ripple="false" @click="hide">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'AppNotify',
  computed: {
    ...mapState({
      color: state => state.notify.color,
      text: state => state.notify.text,
      timeout: state => state.notify.timeout,
      visible: state => state.notify.visible
    })
  },
  methods: {
    ...mapMutations({
      hide: 'notify/hide'
    }),
    input (value) {
      if (!value) this.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
